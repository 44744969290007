import {Component, Input} from '@angular/core';
import {animate, style, transition, trigger} from "@angular/animations";
import {sale_status} from "../../../../../fe-global-components/enums";
import {BffApiService} from "../../shared/bff-api.service";
import {ToasterService} from "../../shared/service/toaster.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-order-line',
  templateUrl: './order-line.component.html',
  styleUrls: ['./order-line.component.scss'],
  animations: [
    trigger('AnimationTrigger0', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.95)' }),
        animate('100ms ease-out', style({  opacity: 1, transform: 'scale(1)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('75ms ease-in', style({ opacity: 0, transform: 'scale(.95)' }))
      ])
    ])
  ]
})
export class OrderLineComponent {
  @Input() order: any;
  isMenu = false;
  isDetail = false;
  editStatus: boolean = false;
  protected readonly sale_status = sale_status;

  statusForm;

  constructor(private bff: BffApiService,
              private fb: FormBuilder,
              private toaster: ToasterService) {
    this.statusForm = this.fb.group({
      status: [null, Validators.required],
    })
  }

  toggleMenu(){
    this.isMenu = !this.isMenu;
  }

  toggleDetails(){
    this.isDetail = !this.isDetail;
  }

  getClass(status : sale_status): string[] {
    let res: string[] = [];
    switch (status) {
      case sale_status.WaitingMoney: {
        res = ['text-gray-700', 'bg-gray-50', 'ring-gray-600/20']
        break
      }
      case sale_status.Refunded: {
        res = ['text-grey-700', 'bg-grey-50', 'ring-grey-600/20']
        break
      }
      case sale_status.Payed: {
        res = ['text-green-700', 'bg-green-50', 'ring-green-600/20']
        break
      }
      case sale_status.Send: {
        res = ['text-green-700', 'bg-green-50', 'ring-green-600/20']
        break
      }
      case sale_status.Pending: {
        res = ['text-orange-700', 'bg-orange-50', 'ring-orange-600/20']
        break
      }
      case sale_status.Returned: {
        res = ['text-orange-700', 'bg-orange-50', 'ring-orange-600/20']
        break
      }
      case sale_status.Retrieved: {
        res = ['text-blue-700', 'bg-blue-50', 'ring-blue-600/20']
        break
      }
      case sale_status.Closed: {
        res = ['text-blue-700', 'bg-blue-50', 'ring-blue-600/20']
        break
      }
      default:
        break
    }
    return res
  }

  toggleStatus() {
    this.editStatus = !this.editStatus
  }

  updateStatus(newStatus:any) {
    this.order.status = newStatus.status
  }

  saveStatus(order_id:any) {
    if(this.statusForm.invalid) {
      this.toaster.showInfo()
      return
    }
    console.log('form valid')
    this.bff.editSaleStatus(order_id, this.statusForm.value).subscribe(data => {
      console.log(data)
      this.toaster.showSuccess()
      this.toggleStatus()
      this.updateStatus(this.statusForm.value)
    })
    // TODO delete when API connected
    this.toaster.showSuccess()
    this.toggleStatus()
    this.updateStatus(this.statusForm.value)

  }


}
