import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {colors, sizes} from "../../../../../fe-global-components/mockup_data/mockup_data";
import {CommonModule, NgForOf} from "@angular/common";
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {ToasterService} from "../../shared/service/toaster.service";
import {BffApiService} from "../../shared/bff-api.service";

@Component({
  selector: 'app-product-variant-form',
  standalone: true,
  imports: [
    NgForOf,
    ReactiveFormsModule,
    CommonModule
  ],
  templateUrl: './product-variant-form.component.html',
  styleUrl: './product-variant-form.component.scss'
})
export class ProductVariantFormComponent implements OnInit {
  form: FormGroup = new FormGroup({
    color: new FormControl(''),
    size: new FormControl(''),
    quantity: new FormControl(''),
  });

  ngOnInit(): void {
    this.form = this.fb.group(
      {
        color: [null, Validators.required],
        quantity: [null, Validators.required],
        size: [42, Validators.required],
      }
    );
  }


  productVariantForm: FormGroup;
  @Output() toggleEvent = new EventEmitter<string>();
  @Output() addVariantEvent = new EventEmitter<string>();

  protected readonly sizes = sizes;
  protected readonly colors = colors;

  constructor( private fb: FormBuilder,
               private toast: ToasterService,
               private bff: BffApiService,) {
    this.productVariantForm = this.fb.group({
      color: [null, Validators.required],
      size: [null, Validators.required],
      quantity: [null, Validators.required],
    })
  }


  get color() { return this.productVariantForm.get('color') }
  get size() { return this.productVariantForm.get('size') }
  get quantity() { return this.productVariantForm.get('quantity') }

  addVariant() {
    if(this.productVariantForm.invalid){
      this.toast.showInfo()
      return
    }
    this.addVariantEvent.emit(this.productVariantForm.value)
    this.toast.showSuccess()
    this.productVariantForm.reset();
  }

  addItemInList(product_id: string|number , values: any, id: any) {
    // this.productList.find(product => product.id === product_id)?.products.push(
    //   {
    //     id: 3243432,
    //     color: values.color,
    //     size: values.size,
    //     quantity: values.quantity,
    //   }
    // )
    //this.product =
    //this.productChange.emit(this.product);
  }

  toggleVariante() {
    this.toggleEvent.emit()
    //this.isAddVariant = !this.isAddVariant
  }

  dataChanged($event: any) {
    console.log($event)
  }
}
