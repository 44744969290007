import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {EMPTY, Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(protected router: Router,
              private _snackBar: MatSnackBar) {}

// https://medium.com/nerd-for-tech/angular-interceptors-for-errors-and-headers-a35372f4304b
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      /**
       * catch error
       */
      catchError((error: HttpErrorResponse) => {
        console.log(error)
        // switch (error.status)
        // {
        //   // case 100:
        //   //   this._snackBar.open('Statut 100', 'OK',{ duration: 3 * 1000 });
        //   //   break;
        //   // case 101:
        //   //   this._snackBar.open('Switching Protocol', 'OK',{ duration: 3 * 1000 });
        //   //   break;
        //   // case 102:
        //   //   this._snackBar.open('processing the request, but no response is available yet.', 'OK',{ duration: 3 * 1000 });
        //   //   break;
        //   // case 103:
        //   //   this._snackBar.open('the user agent start preloading the resources while the server prepares a final response', 'OK',{ duration: 3 * 1000 });
        //   //   break;
        //   // case 200:
        //   //   this._snackBar.open('the request has succeeded', 'OK',{ duration: 3 * 1000 });
        //   //   break;
        //   // case 201:
        //   //   this._snackBar.open('Indicates that the request has succeeded and a new resource has been created as a result.', 'OK',{ duration: 3 * 1000 });
        //   //   break;
        //   // case 202:
        //   //   this._snackBar.open('It is typically used in log running requests and batch processing.', 'OK',{ duration: 3 * 1000 });
        //   //   break;
        //   // case 203:
        //   //   this._snackBar.open('The set presented MAY be a subset or superset of the original version.', 'OK',{ duration: 3 * 1000 });
        //   //   break;
        //   // case 204:
        //   //   this._snackBar.open('The server has fulfilled the request but does not need to return a response body.', 'OK',{ duration: 3 * 1000 });
        //   //   break;
        //   // case 205:
        //   //   this._snackBar.open('Statut 205', 'OK',{ duration: 3 * 1000 });
        //   //   break;
        //   // case 206:
        //   //   this._snackBar.open('206 Partial Content', 'OK',{ duration: 3 * 1000 });
        //   //   break;
        //   // case 207:
        //   //   this._snackBar.open('207 Multi-Status (WebDAV)', 'OK',{ duration: 3 * 1000 });
        //   //   break;
        //   // case 208:
        //   //   this._snackBar.open('208 Already Reported (WebDAV)', 'OK',{ duration: 3 * 1000 });
        //   //   break;
        //   // case 226:
        //   //   this._snackBar.open('226 IM Used', 'OK',{ duration: 3 * 1000 });
        //   //   break;
        //   // case 300:
        //   //   this._snackBar.open('300 Multiple Choices', 'OK',{ duration: 3 * 1000 });
        //   //   break;
        //   // case 301:
        //   //   this._snackBar.open('301 Moved Permanently', 'OK',{ duration: 3 * 1000 });
        //   //   break;
        //   // case 302:
        //   //   this._snackBar.open('302 Found', 'OK',{ duration: 3 * 1000 });
        //   //   break;
        //   // case 303:
        //   //   this._snackBar.open('303 See Other', 'OK',{ duration: 3 * 1000 });
        //   //   break;
        //   // case 304:
        //   //   this._snackBar.open('304 Not Modified', 'OK',{ duration: 3 * 1000 });
        //   //   break;
        //   // case 305:
        //   //   this._snackBar.open('305 Use Proxy (Deprecated)', 'OK',{ duration: 3 * 1000 });
        //   //   break;
        //   // case 306:
        //   //   this._snackBar.open('306 (Unused)', 'OK',{ duration: 3 * 1000 });
        //   //   break;
        //   // case 307:
        //   //   this._snackBar.open('307 Temporary Redirect', 'OK',{ duration: 3 * 1000 });
        //   //   break;
        //   // case 308:
        //   //   this._snackBar.open('308 Permanent Redirect (experimental)', 'OK',{ duration: 3 * 1000 });
        //   //   break;
        //   case 400:
        //     this._snackBar.open('400 Bad Request', 'OK',{ duration: 3 * 1000 });
        //     break;
        //   case 401:
        //     this._snackBar.open('401 Unauthorized', 'OK',{ duration: 3 * 1000 });
        //     break;
        //   case 402:
        //     this._snackBar.open('402 Payment Required (Experimental)', 'OK',{ duration: 3 * 1000 });
        //     break;
        //   case 403:
        //     this._snackBar.open('403 Forbidden', 'OK',{ duration: 3 * 1000 });
        //     break;
        //   case 404:
        //     this._snackBar.open('404 Not Found', 'OK',{ duration: 3 * 1000 });
        //     break;
        //   case 405:
        //     this._snackBar.open('405 Method Not Allowed', 'OK',{ duration: 3 * 1000 });
        //     break;
        //   case 406:
        //     this._snackBar.open('406 Not Acceptable ', 'OK',{ duration: 3 * 1000 });
        //     break;
        //   case 407:
        //     this._snackBar.open('407 Proxy Authentication Required ', 'OK',{ duration: 3 * 1000 });
        //     break;
        //   case 408:
        //     this._snackBar.open('408 Request Timeout ', 'OK',{ duration: 3 * 1000 });
        //     break;
        //   case 409:
        //     this._snackBar.open('409 Conflict ', 'OK',{ duration: 3 * 1000 });
        //     break;
        //   case 410:
        //     this._snackBar.open('410 Gone ', 'OK',{ duration: 3 * 1000 });
        //     break;
        //   case 411:
        //     this._snackBar.open('411 Length Required ', 'OK',{ duration: 3 * 1000 });
        //     break;
        //   case 412:
        //     this._snackBar.open('412 Precondition Failed ', 'OK',{ duration: 3 * 1000 });
        //     break;
        //   case 413:
        //     this._snackBar.open('413 Request Entity Too Large ', 'OK',{ duration: 3 * 1000 });
        //     break;
        //   case 414:
        //     this._snackBar.open('414 Request-URI Too Long ', 'OK',{ duration: 3 * 1000 });
        //     break;
        //   case 415:
        //     this._snackBar.open('415 Unsupported Media Type ', 'OK',{ duration: 3 * 1000 });
        //     break;
        //   case 416:
        //     this._snackBar.open('416 Requested Range Not Satisfiable ', 'OK',{ duration: 3 * 1000 });
        //     break;
        //   case 417:
        //     this._snackBar.open('417 Expectation Failed ', 'OK',{ duration: 3 * 1000 });
        //     break;
        //   case 418:
        //     this._snackBar.open('418 I’m a teapot (RFC 2324) ', 'OK',{ duration: 3 * 1000 });
        //     break;
        //   case 420:
        //     this._snackBar.open('420 Enhance Your Calm (Twitter) ', 'OK',{ duration: 3 * 1000 });
        //     break;
        //   case 422:
        //     this._snackBar.open('422 Unprocessable Entity (WebDAV) ', 'OK',{ duration: 3 * 1000 });
        //     break;
        //   case 423:
        //     this._snackBar.open('423 Locked (WebDAV) ', 'OK',{ duration: 3 * 1000 });
        //     break;
        //   case 424:
        //     this._snackBar.open('424 Failed Dependency (WebDAV) ', 'OK',{ duration: 3 * 1000 });
        //     break;
        //   case 425:
        //     this._snackBar.open('425 Too Early (WebDAV) ', 'OK',{ duration: 3 * 1000 });
        //     break;
        //   case 426:
        //     this._snackBar.open('426 Upgrade Required ', 'OK',{ duration: 3 * 1000 });
        //     break;
        //   case 428:
        //     this._snackBar.open('428 Precondition Required ', 'OK',{ duration: 3 * 1000 });
        //     break;
        //   case 429:
        //     this._snackBar.open('429 Too Many Requests ', 'OK',{ duration: 3 * 1000 });
        //     break;
        //   case 431:
        //     this._snackBar.open('431 Request Header Fields Too Large ', 'OK',{ duration: 3 * 1000 });
        //     break;
        //   case 444:
        //     this._snackBar.open('444 No Response (Nginx) ', 'OK',{ duration: 3 * 1000 });
        //     break;
        //   case 449:
        //     this._snackBar.open('449 Retry With (Microsoft) ', 'OK',{ duration: 3 * 1000 });
        //     break;
        //   case 450:
        //     this._snackBar.open('450 Blocked by Windows Parental Controls (Microsoft) ', 'OK',{ duration: 3 * 1000 });
        //     break;
        //   case 451:
        //     this._snackBar.open('451 Unavailable For Legal Reasons ', 'OK',{ duration: 3 * 1000 });
        //     break;
        //   case 499:
        //     this._snackBar.open('499 Client Closed Request (Nginx) ', 'OK',{ duration: 3 * 1000 });
        //     break;
        //   case 500:
        //     this._snackBar.open('500 Internal Server Error ', 'OK',{ duration: 3 * 1000 });
        //     break;
        //   case 501:
        //     this._snackBar.open('501 Not Implemented ', 'OK',{ duration: 3 * 1000 });
        //     break;
        //   case 502:
        //     this._snackBar.open('502 Bad Gateway ', 'OK',{ duration: 3 * 1000 });
        //     break;
        //   case 503:
        //     this._snackBar.open('503 Service Unavailable ', 'OK',{ duration: 3 * 1000 });
        //     break;
        //   case 504:
        //     this._snackBar.open('504 Gateway Timeout', 'OK',{ duration: 3 * 1000 });
        //     break;
        //   case 505:
        //     this._snackBar.open('505 HTTP Version Not Supported ', 'OK',{ duration: 3 * 1000 });
        //     break;
        //   case 506:
        //     this._snackBar.open('506 Variant Also Negotiates ', 'OK',{ duration: 3 * 1000 });
        //     break;
        //   case 507:
        //     this._snackBar.open('507 Insufficient Storage (WebDAV)', 'OK',{ duration: 3 * 1000 });
        //     break;
        //   case 508:
        //     this._snackBar.open('508 Loop Detected (WebDAV)', 'OK',{ duration: 3 * 1000 });
        //     break;
        //   case 509:
        //     this._snackBar.open('510 Not Extended', 'OK',{ duration: 3 * 1000 });
        //     break;
        //   // default:
        //   //   this._snackBar.open('511 Network Authentication Required', 'OK',
        //   //     { duration: 3 * 1000 });
        // }
        switch (error.error.message) {
          case 'These credentials do not match our records.':
            this._snackBar.open("Les identifiants sont incorrects", 'OK',{ duration: 3 * 1000 });
            break;
          case 'Unauthenticated.':
            this._snackBar.open("Vous n'êtes pas autorisé à acceder à cette ressource.", 'OK',{ duration: 3 * 1000 });
            break;


        }

        return EMPTY;
      })

    )}
}
