import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {product} from "../../../../../fe-global-components/mockup_data/mockup_data";
import {Product} from "../../../../../fe-global-components/interfaces/interfaces";

@Injectable({
  providedIn: 'root'
})
export class ProductListService {
  //TODO regroup all product function here.
  // like add, edit, delete, etc...
  private productSource = new BehaviorSubject(product);
  currentProduct = this.productSource.asObservable();



  private newProductSource = new BehaviorSubject(product);
  currentNewProduct = this.newProductSource.asObservable();


  constructor() { }

  changeProduct(product: Product) {
    this.productSource.next(product)
  }


  saveProduct(product: Product) {
    this.newProductSource.next(product)
  }




}
