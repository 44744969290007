<form [formGroup]="productVariantForm">
  <div>
    <label class="block text-sm font-medium leading-6 text-gray-900">Couleur</label>
    <select
            FormControlName="color"
            class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-stone-600 sm:text-sm sm:leading-6">
      <option *ngFor="let color of colors" value="{{color.value}}">{{color.name}}</option>
    </select>
  </div>
  {{ color?.errors | json}}
  <div>
    <label class="block text-sm font-medium leading-6 text-gray-900">Taille</label>
    <select
            formControlName="size"
            class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-stone-600 sm:text-sm sm:leading-6">
      <option *ngFor="let size of sizes" value="{{size.name}}">{{size.name}}</option>
    </select>
  </div>
  {{ size?.errors | json}}
  <div>
    <label class="block text-sm font-medium leading-6 text-gray-900">Quantité</label>
    <div class="mt-2">
      <input
        formControlName="quantity"
             class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6"
      />
    </div>
  </div>
  {{ quantity?.errors | json}}
  <button (click)="addVariant()">Confirmer</button>
  <button (click)="toggleVariante()">Annuler</button>
</form>
