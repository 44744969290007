<div>
  <div class="md:flex md:items-center md:justify-between py-8">
    <div class="min-w-0 flex-1">
      <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Commandes</h2>
    </div>
    <div class="mt-4 flex md:ml-4 md:mt-0">
      <button type="button"
              class="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
        Factures</button>
<!--      <button type="button"-->
<!--              class="ml-3 inline-flex items-center rounded-md bg-stone-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-stone-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-600">-->
<!--        Afficher les nouvelles requêtes</button>-->
    </div>
  </div>


  <!--  <div class="border-b border-gray-200">-->
  <!--    <div class="sm:flex sm:items-baseline">-->
  <!--      <h3 class="text-base font-semibold leading-6 text-gray-900">Issues</h3>-->
  <!--      <div class="mt-4 sm:ml-10 sm:mt-0">-->
  <!--        <nav class="-mb-px flex space-x-8">-->
  <!--          &lt;!&ndash; Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" &ndash;&gt;-->
  <!--          <a href="#" class="border-indigo-500 text-indigo-600 whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium" aria-current="page">Open</a>-->
  <!--          <a href="#" class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium">Closed</a>-->
  <!--        </nav>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->

<!--  <router-outlet></router-outlet>-->

</div>

<div>
  <select id="orders"
          (change)="onChange($event)"
          name="orders" class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-stone-600 sm:text-sm sm:leading-6">
    <option value="-1">Toutes les commandes</option>
    <option *ngFor="let status of order_status" value="{{status.name}}">{{status.name}}</option>
  </select>
</div>

<ul role="list" class="divide-y divide-gray-100">
  <li *ngFor="let order of filteredOrder" class="bg-white border-b-1 border-gray-500 px-2">
    <app-order-line [order]="order"></app-order-line>
  </li>
</ul>
