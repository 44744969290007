import {CanActivateFn, Router} from '@angular/router';
import {Role} from "../../../../../fe-global-components/interfaces/enums";
import {inject} from "@angular/core";
import {AuthService} from "./auth.service";

export const routeGuard: CanActivateFn = (route, state) => {
  const router: Router = inject(Router);
  console.log(router)
  const userRole: Role = inject(AuthService).getUserRole();
  const expectedRoles: Role[] = route.data['roles'];
  const hasRole: boolean = expectedRoles.some((role) => userRole === role);

  return hasRole || router.navigate(['login']);
};
