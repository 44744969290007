<div>
  <div class="md:flex md:items-center md:justify-between py-8">
    <div class="min-w-0 flex-1">
      <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Vestra Shop</h2>
    </div>
    <div class="mt-4 flex md:ml-4 md:mt-0">
<!--      <button type="button"-->
<!--              class="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">-->
<!--        Factures</button>-->
      <button type="button"
              (click)="toggleTableRows()"
              class="rounded-md bg-stone-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-stone-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-600">
        Afficher/masquer toutes les variantes</button>
    </div>
  </div>


  <!--  <div class="border-b border-gray-200">-->
  <!--    <div class="sm:flex sm:items-baseline">-->
  <!--      <h3 class="text-base font-semibold leading-6 text-gray-900">Issues</h3>-->
  <!--      <div class="mt-4 sm:ml-10 sm:mt-0">-->
  <!--        <nav class="-mb-px flex space-x-8">-->
  <!--          &lt;!&ndash; Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" &ndash;&gt;-->
  <!--          <a href="#" class="border-indigo-500 text-indigo-600 whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium" aria-current="page">Open</a>-->
  <!--          <a href="#" class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium">Closed</a>-->
  <!--        </nav>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->

  <!--  <router-outlet></router-outlet>-->

</div>


<!-- Master Toggle Button -->

<!--<a mat-raised-button (click)="filterProduct()" color="primary">Filtrer les produits</a>-->


<table mat-table [dataSource]="dataProductsList" multiTemplateDataRows>
  <!--  <ng-container matColumnDef="checkbox">-->
  <!--    <th mat-header-cell *matHeaderCellDef> </th>-->
  <!--    <td mat-cell *matCellDef="let product">-->
  <!--      <mat-checkbox class="example-margin"></mat-checkbox>-->
  <!--    </td>-->
  <!--  </ng-container>-->

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef> Nom </th>
    <td mat-cell *matCellDef="let product"> {{product.name}} </td>
  </ng-container>

  <ng-container matColumnDef="categories">
    <th mat-header-cell *matHeaderCellDef> Catégories </th>
    <td mat-cell *matCellDef="let product"> {{product.categories}} </td>
  </ng-container>

  <ng-container matColumnDef="brand">
    <th mat-header-cell *matHeaderCellDef> Marque </th>
    <td mat-cell *matCellDef="let product"> {{product.brand}} </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef> </th>
    <td mat-cell *matCellDef="let product">
      <div class="flex justify-end">
        <button (click)="product.isExpanded = !product.isExpanded"
                class="px-2 py-1 text-sm font-semibold text-gray-900 hover:bg-gray-50">
          <svg viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"
               class="mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500 inline-block"><path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" class="ng-tns-c2145528021-17"></path>
          </svg><span class="pr-1">{{product.products.length}} variantes</span>
          <!--        <svg class="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">-->
          <!--          <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />-->
          <!--        </svg>-->
        </button>
        <button type="button"
                (click)="editVariant(product)"
                class="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500 inline-block">
            <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
          </svg><span class="pr-1">Éditer</span>
          <!--        <svg class="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">-->
          <!--          <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />-->
          <!--        </svg>-->
        </button>
      </div>
    </td>
  </ng-container>


  <!-- Template for details row -->
  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let product" [attr.colspan]="displayedColumns.length">

      <div class="row student-product-detail" [@detailExpand]="product.isExpanded ? 'expanded' : 'collapsed'">

        <ul role="list" class="divide-y divide-gray-100">
          <li *ngFor="let article of product.products"
              class="relative flex justify-between gap-x-6 py-2 px-4">
            <div class="flex min-w-0 gap-x-4">
              <div class="min-w-0 flex-auto">
                <div *ngIf="!article.isEdit">
                  <p class="text-sm font-semibold leading-6 text-gray-900">
                  <span class="">
                     {{article.color.name }}
                  </span>
                  </p>
                  <p class="mt-1 flex text-xs leading-5 text-gray-500">
                  <span class="relative truncate hover:underline">
                    {{article.size.name }} | Quantité: {{article.quantity}}</span>
                  </p>
                </div>
                <div *ngIf="article.isEdit">
                  <div>
                    <label for="color-{{article.id}}" class="block text-sm font-medium leading-6 text-gray-900">Couleur</label>
                    <select id="color-{{article.id}}" name="color-{{article.id}}"
                            class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-stone-600 sm:text-sm sm:leading-6">
                      <option *ngFor="let color of colors">{{color.name}}</option>
                    </select>
                  </div>
                  <div>
                    <label for="size-{{article.id}}" class="block text-sm font-medium leading-6 text-gray-900">Taille</label>
                    <select id="size-{{article.id}}" name="size-{{article.id}}"
                            class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-stone-600 sm:text-sm sm:leading-6">
                      <option *ngFor="let size of sizes">{{size.name}}</option>
                      <option *ngFor="let size of sizes">{{size.name}}</option>
                    </select>
                  </div>
                  <div>
                    <label for="quantity-{{article.id}}" class="block text-sm font-medium leading-6 text-gray-900">Quantité</label>
                    <div class="mt-2">
                      <input type="number" name="quantity-{{article.id}}" id="quantity-{{article.id}}"
                             class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6"
                             placeholder="42" aria-describedby="quantity" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex shrink-0 items-center gap-x-4">
              <a *ngIf="!article.isEdit" (click)="deleteItem(article)" class="text-stone-600 hover:text-stone-900">
                Retirer de Vestra<span class="sr-only">, {{ article }}</span></a>
              <a *ngIf="!article.isEdit" (click)="toggleEdit(article)" class="text-stone-600 hover:text-stone-900">
                Modifier<span class="sr-only">, {{ article }}</span></a>
              <div *ngIf="article.isEdit">
                <a class="text-stone-600 hover:text-stone-900" (click)="toggleEdit(article)">
                  Annuler<span class="sr-only">, {{ article }}</span></a>
                <a (click)="editVariant(article)" class="text-stone-600 hover:text-stone-900">
                  Sauvegarder<span class="sr-only">, {{ article }}</span></a>
              </div>
            </div>
          </li>

          <li class="relative flex justify-between gap-x-6 py-5 pt-2 pb-4">
            <div class="flex min-w-0 gap-x-4" *ngIf="isAddVariant">
<!--              <app-product-variant-form (toggleEvent)="toggleVariante()" (addVariantEvent)="addVariant(product.id, $event)"></app-product-variant-form>-->
              <form [formGroup]="productVariantForm">
                <h4>Ajouter une nouvelle variante de produit</h4>
                <div>
                  <label for="color-new" class="block text-sm font-medium leading-6 text-gray-900">Couleur</label>
                  <select id="color-new" name="color-new"
                          formControlName="color"
                          class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-stone-600 sm:text-sm sm:leading-6">
                    <option *ngFor="let color of colors" value="{{color.name}}">{{color.name}}</option>
                  </select>
                </div>
                <div>
                  <label for="size-new" class="block text-sm font-medium leading-6 text-gray-900">Taille</label>
                  <select id="size-new" name="size-new"
                          formControlName="size"
                          class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-stone-600 sm:text-sm sm:leading-6">
                    <option *ngFor="let size of sizes" value="{{size.name}}">{{size.name}}</option>
                  </select>
                </div>
                <div>
                  <label for="quantity-new" class="block text-sm font-medium leading-6 text-gray-900">Quantité</label>
                  <div class="mt-2">
                    <input type="number" name="quantity-new" id="quantity-new"
                           formControlName="quantity"
                           class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6"
                           placeholder="42" aria-describedby="quantity" />
                  </div>
                </div>
                <button type="button"
                        (click)="addVariant(product.id)"
                        class="rounded-md bg-stone-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-stone-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-600">
                  Confirmer</button>
                <button (click)="toggleVariante()" type="button" class="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                  Annuler</button>
              </form>
            </div>

            <div class="flex min-w-0 gap-x-4" *ngIf="!isAddVariant">
              <div class="min-w-0 flex-auto my-4">
                <p class="text-sm leading-6 text-gray-900">
                  <button (click)="toggleVariante()" type="button" class="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                    + Ajouter une nouvelle variante de produit</button>
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
  <tr mat-row *matRowDef="let product; columns: displayedColumns;" class="student-product-row"
      [class.student-expanded-row]="product.isExpanded"></tr>

  <!-- Extra row to show detail content column -->
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="student-detail-row"></tr>

</table>
