<div>
  <div class="md:flex md:items-center md:justify-between py-8">
    <div class="min-w-0 flex-1">
      <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Stock du magasin</h2>
    </div>
    <div class="mt-4 flex md:ml-4 md:mt-0">
      <!--      <button type="button"-->
      <!--              class="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">-->
      <!--        Factures</button>-->
      <button (click)="refreshStock()" type="button" class="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
        Mettre à jour le stock</button>
    </div>
  </div>


  <!--  <div class="border-b border-gray-200">-->
  <!--    <div class="sm:flex sm:items-baseline">-->
  <!--      <h3 class="text-base font-semibold leading-6 text-gray-900">Issues</h3>-->
  <!--      <div class="mt-4 sm:ml-10 sm:mt-0">-->
  <!--        <nav class="-mb-px flex space-x-8">-->
  <!--          &lt;!&ndash; Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" &ndash;&gt;-->
  <!--          <a href="#" class="border-indigo-500 text-indigo-600 whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium" aria-current="page">Open</a>-->
  <!--          <a href="#" class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium">Closed</a>-->
  <!--        </nav>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->

  <!--  <router-outlet></router-outlet>-->

</div>


<!--<div>-->
<!--  <div class="mx-auto max-w-7xl overflow-hidden">-->
<!--    <h2 class="sr-only">Products</h2>-->

<!--    <div class="-mx-px grid grid-cols-2 border-l border-gray-200 sm:mx-0 md:grid-cols-3 lg:grid-cols-4">-->
<!--      <div *ngFor="let stockProd of products" class="group relative border-b border-r border-gray-200 p-4 sm:p-6 bg-white">-->
<!--        <div class="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-200 group-hover:opacity-75">-->
<!--          <img src="https://tailwindui.com/img/ecommerce-images/category-page-05-image-card-01.jpg" alt="TODO" class="h-full w-full object-cover object-center">-->
<!--        </div>-->
<!--        <div class="pb-4 pt-10 text-center">-->
<!--          <h3 class="text-sm font-medium text-gray-900">-->
<!--&lt;!&ndash;-->
<!--              <span aria-hidden="true" class="absolute inset-0"></span>-->
<!--&ndash;&gt;-->
<!--              {{stockProd.name}}-->
<!--          </h3>-->
<!--&lt;!&ndash;          <div class="mt-3 flex flex-col items-center">&ndash;&gt;-->
<!--&lt;!&ndash;            <p class="sr-only">5 out of 5 stars</p>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="flex items-center">&ndash;&gt;-->
<!--&lt;!&ndash;              <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">&ndash;&gt;-->
<!--&lt;!&ndash;                <path fill-rule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clip-rule="evenodd" />&ndash;&gt;-->
<!--&lt;!&ndash;              </svg>&ndash;&gt;-->
<!--&lt;!&ndash;              <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">&ndash;&gt;-->
<!--&lt;!&ndash;                <path fill-rule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clip-rule="evenodd" />&ndash;&gt;-->
<!--&lt;!&ndash;              </svg>&ndash;&gt;-->
<!--&lt;!&ndash;              <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">&ndash;&gt;-->
<!--&lt;!&ndash;                <path fill-rule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clip-rule="evenodd" />&ndash;&gt;-->
<!--&lt;!&ndash;              </svg>&ndash;&gt;-->
<!--&lt;!&ndash;              <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">&ndash;&gt;-->
<!--&lt;!&ndash;                <path fill-rule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clip-rule="evenodd" />&ndash;&gt;-->
<!--&lt;!&ndash;              </svg>&ndash;&gt;-->
<!--&lt;!&ndash;              <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">&ndash;&gt;-->
<!--&lt;!&ndash;                <path fill-rule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clip-rule="evenodd" />&ndash;&gt;-->
<!--&lt;!&ndash;              </svg>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;            <p class="mt-1 text-sm text-gray-500">38 reviews</p>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--          <p class="mt-4 text-base font-medium text-gray-900">{{stockProd.old_price}} CHF</p>-->
<!--          <button type="button"-->
<!--                  (click)="addProductToVestra(stockProd)"-->
<!--                  class="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">-->
<!--            Ajouter le produit sur Vestra</button>-->
<!--        </div>-->
<!--      </div>-->

<!--      &lt;!&ndash; More products... &ndash;&gt;-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->



<table mat-table [dataSource]="dataProductsList" multiTemplateDataRows>
  <!--  <ng-container matColumnDef="checkbox">-->
  <!--    <th mat-header-cell *matHeaderCellDef> </th>-->
  <!--    <td mat-cell *matCellDef="let product">-->
  <!--      <mat-checkbox class="example-margin"></mat-checkbox>-->
  <!--    </td>-->
  <!--  </ng-container>-->

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef> Nom </th>
    <td mat-cell *matCellDef="let product"> {{product.name}} </td>
  </ng-container>

  <ng-container matColumnDef="categories">
    <th mat-header-cell *matHeaderCellDef> Catégories </th>
    <td mat-cell *matCellDef="let product"> {{product.categories}} </td>
  </ng-container>

  <ng-container matColumnDef="brand">
    <th mat-header-cell *matHeaderCellDef> Marque </th>
    <td mat-cell *matCellDef="let product"> {{product.brand}} </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef> </th>
    <td mat-cell *matCellDef="let product">
      <span (click)="addProductToVestra(product)"
            class="px-2 py-1 text-sm font-semibold text-gray-900 hover:bg-gray-50">
        Ajouter le produit sur Vestra
      </span>
    </td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
  <tr mat-row *matRowDef="let product; columns:displayedColumns;" class="student-product-row"
      [class.student-expanded-row]="product.isExpanded"></tr>
</table>
