<div class="m-4">
  <div class="max-w-2xl mx-auto">
    <form [formGroup]="productVarianForm">
      <div > <!-- class="border-b border-gray-900/10 pb-12" -->
        <button (click)="nav.goToStocks()"><- Retour à la liste d'articles</button>
        <h2 class="text-base font-semibold leading-7 text-gray-900">Produit</h2>
        <p class="mt-1 text-sm leading-6 text-gray-600">Ajouter les informations générales</p>

        <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div class="col-span-full">
            <label for="cover-photo" class="block text-sm font-medium leading-6 text-gray-900">Image du produit</label>
            <div id="cover-photo" class="bg-white mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
              <div class="text-center">
                <svg class="mx-auto h-12 w-12 text-gray-300" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z" clip-rule="evenodd" />
                </svg>
                <div class="mt-4 flex text-sm leading-6 text-gray-600">
                  <label for="file-upload" class="relative cursor-pointer rounded-md bg-white font-semibold text-stone-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-stone-600 focus-within:ring-offset-2 hover:text-stone-500">
                    <span>Charger un fichier</span>
                    <input id="file-upload" name="file-upload" type="file" class="sr-only">
                  </label>
                  <p class="pl-1">ou glisser</p>
                </div>
                <p class="text-xs leading-5 text-gray-600">PNG, JPG < 10MB</p>
              </div>
            </div>
          </div>

          <div class="col-span-full">
            <label for="product-name" class="block text-sm font-medium leading-6 text-gray-900">Nom du produit</label>
            <div class="mt-2">
              <input type="text" name="product-name" id="product-name"
                     formControlName="name"
                     class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6">
            </div>
          </div>

          <div class="col-span-full">
            <label for="brand" class="block text-sm font-medium leading-6 text-gray-900">Marque</label>
            <div class="mt-2">
              <input type="text" name="brand" id="brand"
                     formControlName="brand"
                     class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6">
            </div>
          </div>

          <div class="col-span-full">
            <label for="categories" class="block text-sm font-medium leading-6 text-gray-900">Catégories du produit</label>
            <div class="mt-2">
              <select id="categories" name="categories"
                      formControlName="categories"
                      class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:max-w-xs sm:text-sm sm:leading-6">
                <option>
                  <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                  </svg>
                  Product cat 1</option>
                <option>Product cat 2</option>
                <option>Product cat 3</option>
                <option>Product cat 4</option>
              </select>
            </div>
          </div>

          <div class="col-span-full">
            <label for="description" class="block text-sm font-medium leading-6 text-gray-900">Description</label>
            <div class="mt-2">
              <textarea id="description" name="description" rows="3"
                        formControlName="description"
                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6">
              </textarea>
            </div>
          </div>

          <div class="sm:col-span-2 sm:col-start-1">
            <label for="old_price" class="block text-sm font-medium leading-6 text-gray-900">Prix initial</label>
            <div class="mt-2">
              <input type="old_price" name="old_price" id="old_price"
                     formControlName="old_price"
                     class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6">
            </div>
          </div>

          <div class="sm:col-span-2">
            <label for="sale" class="block text-sm font-medium leading-6 text-gray-900">Rabais appliqué sur Vestra</label>
            <div class="mt-2">
              <select id="sale" name="sale"
                      formControlName="sale"
                      class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:max-w-xs sm:text-sm sm:leading-6">
                <option *ngFor="let v of salesValues" value="{{v.value}}">{{v.name}}</option>
              </select>
            </div>
          </div>

          <div class="sm:col-span-2">
            <label class="block text-sm font-medium leading-6 text-gray-900">Prix sur Vestra</label>
            <div class="mt-2">
              <p>{{productVarianForm.value['old_price'] - (productVarianForm.value['old_price'] * productVarianForm.value['sale'] / 100)}} CHF</p>
            </div>
          </div>
        </div>

        <div class="col-span-full">
          <label for="categories" class="block text-sm font-medium leading-6 text-gray-900">Durée de l'offre sur Vestra</label>
          <div class="mt-2">
            <select id="duration" name="duration"
                    formControlName="duration"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:max-w-xs sm:text-sm sm:leading-6">
              <option>1 mois</option>
              <option>2 mois</option>
              <option>3 mois</option>
              <option>4 mois</option>
              <option>5 mois</option>
              <option>6 mois</option>
            </select>
          </div>
        </div>

        <div class="col-span-full">
          <label for="categories" class="block text-sm font-medium leading-6 text-gray-900">Durée de l'offre sur Vestra</label>
          <div class="mt-2">
            <select id="isPublished" name="duration"
                    formControlName="duration"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:max-w-xs sm:text-sm sm:leading-6">
              <option>En brouillon</option>
              <option>Publié</option>
            </select>
          </div>
        </div>

      </div>




  <!--      <div>-->



  <!--        <label id="listbox-label" class="sr-only">Statut de l'article</label>-->
  <!--        <div class="relative w-[fit-content]">-->
  <!--          <div class="inline-flex divide-x divide-stone-700 rounded-md shadow-sm">-->
  <!--            <div class="inline-flex items-center gap-x-1.5 rounded-l-md bg-stone-600 py-2 px-3 text-white shadow-sm">-->
  <!--              <svg class="-ml-0.5 h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">-->
  <!--                <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />-->
  <!--              </svg>-->
  <!--              <p class="text-sm font-semibold">{{ current.name }}</p>-->
  <!--            </div>-->
  <!--            <button type="button" (click)="toggleSelect()" class="inline-flex items-center rounded-l-none rounded-r-md bg-stone-600 p-2 hover:bg-stone-700 focus:outline-none focus:ring-2 focus:ring-stone-600 focus:ring-offset-2 focus:ring-offset-gray-50" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label">-->
  <!--              <span class="sr-only">Modifier le statut</span>-->
  <!--              <svg class="h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">-->
  <!--                <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />-->
  <!--              </svg>-->
  <!--            </button>-->
  <!--          </div>-->

  <!--          &lt;!&ndash;-->
  <!--            Select popover, show/hide based on select state.-->

  <!--            Entering: ""-->
  <!--              From: ""-->
  <!--              To: ""-->
  <!--            Leaving: "transition ease-in duration-100"-->
  <!--              From: "opacity-100"-->
  <!--              To: "opacity-0"-->
  <!--          &ndash;&gt;-->
  <!--          <ul @opacityLeave *ngIf='isSelect' class="absolute right-0 z-10 mt-2 w-72 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option-0">-->
  <!--            &lt;!&ndash;-->
  <!--              Select option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.-->

  <!--              Highlighted: "bg-stone-600 text-white", Not Highlighted: "text-gray-900"-->
  <!--            &ndash;&gt;-->
  <!--            <li *ngFor="let item of list" (click)="select(item)" (mouseover)="setHighlight(item)"-->
  <!--                [ngClass]="{-->
  <!--        'text-white': highlight?.id === item.id,-->
  <!--        'bg-stone-600': highlight?.id === item.id,-->
  <!--        'text-gray-900': highlight?.id !== item.id-->
  <!--        }"-->
  <!--                class="text-gray-900 cursor-default select-none p-4 text-sm" id="listbox-option-0" role="option">-->
  <!--              <div class="flex flex-col">-->
  <!--                <div class="flex justify-between">-->
  <!--                  &lt;!&ndash; Selected: "font-semibold", Not Selected: "font-normal" &ndash;&gt;-->
  <!--                  <p [ngClass]="{-->
  <!--              'font-semibold': current.id === item.id,-->
  <!--              'font-normal': current.id !== item.id-->
  <!--              }" class="font-normal">-->
  <!--                    {{ item.name }}-->
  <!--                  </p>-->
  <!--                  &lt;!&ndash;-->
  <!--                    Checkmark, only display for selected option.-->

  <!--                    Highlighted: "text-white", Not Highlighted: "text-stone-600"-->
  <!--                  &ndash;&gt;-->
  <!--                  <span *ngIf="item.id === current.id"-->
  <!--                        [ngClass]="{-->
  <!--              'text-white': highlight?.id === item.id,-->
  <!--              'text-stone-600': highlight?.id !== item.id-->
  <!--              }"-->
  <!--                        class="text-stone-600">-->
  <!--              <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">-->
  <!--                <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />-->
  <!--              </svg>-->
  <!--            </span>-->
  <!--                </div>-->
  <!--                &lt;!&ndash; Highlighted: "text-stone-200", Not Highlighted: "text-gray-500" &ndash;&gt;-->
  <!--                <p [ngClass]="{-->
  <!--            'text-stone-200': highlight?.id === item.id,-->
  <!--            'text-gray-500': highlight?.id !== item.id-->
  <!--            }"-->
  <!--                   class="text-gray-500 mt-2">-->
  <!--                  {{ item.description }}-->
  <!--                </p>-->
  <!--              </div>-->
  <!--            </li>-->

  <!--          </ul>-->
  <!--        </div>-->


  <button type="button"
          (click)="saveChanges()"
          class="mt-8 text-white inline-flex items-center rounded-md bg-stone-600 p-2 hover:bg-stone-700 focus:outline-none focus:ring-2 focus:ring-stone-600 focus:ring-offset-2 focus:ring-offset-gray-50"
          aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label">
    <span>Sauvegarder les modifications</span>
  </button>

</form>
</div>
</div>

