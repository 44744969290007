<div class="py-16 sm:py-24">
  <div class="mx-auto max-w-7xl sm:px-2 lg:px-8">
    <div class="mx-auto max-w-2xl px-4 lg:max-w-4xl lg:px-0">
      <h1 class="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">Gérer mon compte</h1>
      <p class="mt-2 text-sm text-gray-500">Mes informations personnels et préférences.</p>
    </div>

    <ul role="list" class="divide-y divide-gray-100 mt-8 mx-auto max-w-2xl px-4 lg:max-w-4xl lg:px-0">
      <li class="flex items-center justify-between gap-x-6 py-5 bg-white px-4">
        <div class="min-w-0">
          <div class="flex items-start gap-x-3">
            <h3 class="text-base font-semibold leading-7 text-gray-900">Informations publiques</h3>
          </div>
          <div *ngIf="!isChangePublicInfos" class="mt-1 flex flex-col gap-x-2 text-xs leading-5 text-gray-500">
            <img [src]="user.logo" class="w-16">
            <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">{{user.name}}</p>
            <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">{{user.street}}, {{user.streetnumber}}</p>
            <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">{{user.zip}} {{user.city}}, {{user.state}}</p>
            <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">"{{user.description}}"</p>

          </div>
          <div *ngIf="isChangePublicInfos" class="mt-1 flex flex-col gap-x-2 text-xs leading-5 text-gray-500">
            <form class="space-y-6 grid grid-cols-1 gap-x-6 sm:grid-cols-6" [formGroup]="publicInfos" >
              <div class="sm:col-span-4">
                <label for="name" class="block text-sm font-medium leading-6 text-gray-900">Nom du magasin</label>
                <div class="mt-2  bg-white">
                  <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-stone-600 sm:max-w-md">
                    <input type="text"
                           formControlName="name"
                           name="name" id="name" autocomplete="name" class="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" placeholder="Nom">
                  </div>
                </div>
              </div>

              <div class="sm:col-span-4">
                <label for="street-address" class="block text-sm font-medium leading-6 text-gray-900">Rue</label>
                <div class="mt-2">
                  <input type="text"
                         formControlName="street"
                         name="street-address" id="street-address" autocomplete="street-address" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6">
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="street-address" class="block text-sm font-medium leading-6 text-gray-900">n°</label>
                <div class="mt-2">
                  <input type="text"
                         formControlName="street_number"
                         name="street-address" id="street" autocomplete="street" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6">
                </div>
              </div>

              <div class="sm:col-span-2 sm:col-start-1">
                <label for="postal-code" class="block text-sm font-medium leading-6 text-gray-900">ZIP</label>
                <div class="mt-2">
                  <input type="text"
                         formControlName="zip"
                         name="postal-code" id="postal-code" autocomplete="postal-code" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6">
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="city" class="block text-sm font-medium leading-6 text-gray-900">Ville</label>
                <div class="mt-2">
                  <input type="text"
                         formControlName="city"
                         name="city" id="city" autocomplete="city" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6">
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="state" class="block text-sm font-medium leading-6 text-gray-900">Canton</label>
                <div class="mt-2">
                  <select id="state"
                          formControlName="state"
                          name="state" autocomplete="state" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:max-w-xs sm:text-sm sm:leading-6">
                    <!--                      <option *ngFor="let state of states">{{state.name}}</option>-->
                  </select>
                </div>
              </div>
              <div class="col-span-full">
                <label for="about" class="block text-sm font-medium leading-6 text-gray-900">A propos</label>
                <div class="mt-2">
                <textarea id="about"
                          formControlName="description"
                          name="about" rows="3" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6"></textarea>
                </div>
                <p class="mt-3 text-sm leading-6 text-gray-600">Quelques lignes sur votre boutique</p>
              </div>

              <div class="col-span-full">
                <label for="cover-photo" class="block text-sm font-medium leading-6 text-gray-900">Logo</label>
                <div id="cover-photo" class="bg-white mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                  <div class="text-center">
                    <svg class="mx-auto h-12 w-12 text-gray-300" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z" clip-rule="evenodd" />
                    </svg>
                    <div class="mt-4 flex text-sm leading-6 text-gray-600">
                      <label for="file-upload" class="relative cursor-pointer rounded-md bg-white font-semibold text-stone-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-stone-600 focus-within:ring-offset-2 hover:text-stone-500">
                        <span>Charger un fichier</span>
                        <input id="file-upload"
                               formControlName="logo"
                               name="file-upload" type="file" class="sr-only">
                      </label>
                      <p class="pl-1">ou glisser</p>
                    </div>
                    <p class="text-xs leading-5 text-gray-600">PNG, JPG < 10MB</p>
                  </div>
                </div>
              </div>

              <div class="">
                <button (click)="togglePublicInfos()" type="button" class="text-sm font-semibold leading-6 text-gray-900">Annuler</button>
                <button type="submit"
                        (click)="updPublicInfos()"
                        class="rounded-md bg-stone-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-stone-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-600">Modifier</button>
              </div>
            </form>
          </div>
        </div>
        <div class="flex flex-col">
          <a (click)="togglePublicInfos()"  *ngIf="!isChangePublicInfos" class="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">Modifier</a>
        </div>
      </li>
      <li class="flex items-center justify-between gap-x-6 py-5 bg-white px-4">
        <div class="min-w-0">
          <div class="flex items-start gap-x-3">
            <h3 class="text-base font-semibold leading-7 text-gray-900">Personne de contact</h3>
          </div>
          <div *ngIf="!editPersonnalInfos" class="mt-1 flex flex-col gap-x-2 text-xs leading-5 text-gray-500">
            <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">{{user.firstname}} {{user.lastname}}</p>
            <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">{{user.phone}}</p>
          </div>
          <div *ngIf="editPersonnalInfos" class="mt-1 flex flex-col gap-x-2 text-xs leading-5 text-gray-500">
            <form class="space-y-6 grid grid-cols-1 gap-x-6 sm:grid-cols-6" [formGroup]="contactInfos">
              <div class="sm:col-span-2">
                <label for="firstname" class="block text-sm font-medium leading-6 text-gray-900">Prénom</label>
                <div class="mt-2">
                  <input type="text"
                         formControlName="firstname"
                         name="firstname" id="firstname" autocomplete="firstname" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6">
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="lastname" class="block text-sm font-medium leading-6 text-gray-900">Nom de Famille</label>
                <div class="mt-2">
                  <input type="text"
                         formControlName="lastnamer"
                         name="lastname" id="lastname" autocomplete="lastname" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6">
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="lastname" class="block text-sm font-medium leading-6 text-gray-900">Téléphone</label>
                <div class="mt-2">
                  <input type="text"
                         formControlName="phone"
                         name="phone" id="phone" autocomplete="phone" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6">
                </div>
              </div>

              <div class="">
                <button (click)="toggleEditPersonalInfos()" type="button" class="text-sm font-semibold leading-6 text-gray-900">Annuler</button>
                <button type="submit"
                        (click)="updPublicInfos()"
                        class="rounded-md bg-stone-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-stone-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-600">Modifier</button>
              </div>
            </form>
          </div>
        </div>
        <div class="flex flex-col">
          <a (click)="toggleEditPersonalInfos()"  *ngIf="!editPersonnalInfos" class="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">Modifier</a>
        </div>
      </li>
      <li class="flex items-center justify-between gap-x-6 py-5 bg-white px-4">
        <div class="min-w-0">
          <div class="flex items-start gap-x-3">
            <h3 class="text-base font-semibold leading-7 text-gray-900">Logiciel de Caisse</h3>
          </div>
          <div *ngIf="!editCashInfos" class="mt-1 flex flex-col gap-x-2 text-xs leading-5 text-gray-500">
            <p *ngIf="cashProgram.length > 0" class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
              <span *ngFor="let prog of cashProgram" class="text-xs">{{prog.name}}<br>{{prog.url}}</span>
            </p>
            <p *ngIf="cashProgram.length == 0" class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
              Pas de logiciel de caisse enregistré pour l'instant
            </p>
          </div>
          <div *ngIf="editCashInfos" class="mt-1 flex flex-col gap-x-2 text-xs leading-5 text-gray-500">
            <form [formGroup]="cashProgrammForm">
              <div class="sm:col-span-3">
                <label for="name" class="block text-sm font-medium leading-6 text-gray-900">Name</label>
                <div class="mt-2">
                  <select formControlName="name"
                          id="name" name="name" autocomplete="cash-programme" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:max-w-xs sm:text-sm sm:leading-6">
                    <option>bcprisme</option>
                    <option>cashflow</option>
                  </select>
                </div>
              </div>
              <div class="sm:col-span-4">
                <label for="url" class="block text-sm font-medium leading-6 text-gray-900">URL</label>
                <div class="mt-2">
                  <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-stone-600 sm:max-w-md">
                    <span class="flex select-none items-center pl-3 text-gray-500 sm:text-sm">https://</span>
                    <input formControlName="url"
                           type="text" name="url" id="url" autocomplete="url" class="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" placeholder="vpn.bestinfo.ch:54789">
                  </div>
                </div>
              </div>
              <div class="sm:col-span-4">
                <label for="secret" class="block text-sm font-medium leading-6 text-gray-900">secret</label>
                <div class="mt-2">
                  <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-stone-600 sm:max-w-md">
                    <input formControlName="secret"
                           type="text" name="secret" id="secret" autocomplete="username" class="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" placeholder="123456">
                  </div>
                </div>
              </div>
              <button type="button"
                      (click)="sendCashProgramChanges(cashProgrammForm)"
                      class="rounded-md bg-stone-600 px-3 py-2 text-sm font-semibold text-white shadow-sm
            hover:bg-stone-500 focus-visible:outline focus-visible:outline-2
            focus-visible:outline-offset-2 focus-visible:outline-stone-600">Save changes</button>
              <button type="button"
                      (click)="toggleEditCashInfos()"
                      class="rounded-md px-3 py-2 text-sm font-semibold shadow-sm
            focus-visible:outline focus-visible:outline-2
            focus-visible:outline-offset-2 focus-visible:outline-stone-600">Annuler</button>
            </form>
          </div>
        </div>
        <div class="flex flex-col">
          <a (click)="toggleEditCashInfos()"  *ngIf="!isChangePWD" class="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">Modifier</a>
        </div>
      </li>
      <li class="flex items-center justify-between gap-x-6 py-5 bg-white px-4 divide-y divide-gray-100">
        <div class="min-w-0">
          <div class="flex items-start gap-x-3">
            <h3 class="text-base font-semibold leading-7 text-gray-900">Mon email</h3>
            <p class="rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset text-green-700 bg-green-50 ring-green-600/20">Verifié</p>
          </div>
          <div class="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
            <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">{{user.email}}</p>
          </div>
        </div>
        <div class="flex flex-none items-center gap-x-4">
          <a href="#" class="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">Verifier à nouveau</a>
        </div>
      </li>
      <li class="flex items-center justify-between gap-x-6 py-5 bg-white px-4">
        <div class="min-w-0">
          <div class="flex items-start gap-x-3">
            <h3 class="text-base font-semibold leading-7 text-gray-900">Modifier le mot de passe</h3>

          </div>
          <div class="mt-1 flex flex-col gap-x-2 text-xs leading-5 text-gray-500">
            <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">***********</p>
            <div *ngIf="isChangePWD">
              <form class="space-y-6" [formGroup]="changePassword" >
                <div>
                  <label for="email" class="label">Mot de passe actuel</label>
                  <div class="mt-2">
                    <input id="email" name="email" type="email"
                           formControlName="email"
                           autocomplete="email" required class="input">
                  </div>
                </div>

                <div>
                  <div class="flex items-center justify-between">
                    <label for="password" class="label">Nouveau mot de passe</label>
                  </div>
                  <div class="mt-2">
                    <input id="password" name="password" type="password"
                           formControlName="password"
                           autocomplete="current-password" required class="input">
                  </div>
                </div>

                <div>
                  <div class="flex items-center justify-between">
                    <label for="password" class="label">Répéter le nouveau mot de passe</label>
                  </div>
                  <div class="mt-2">
                    <input id="re_password" name="password" type="password"
                           formControlName="password_confirmation"
                           autocomplete="current-password" required class="input">
                  </div>
                </div>

                <div>
                  <button type="submit" (click)="submit()" class="btn">Changer le mot de passe</button>           <button (click)="togglePWD()"  class="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">Annuler</button>

                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="flex flex-none items-center gap-x-4">
          <a (click)="togglePWD()"  *ngIf="!isChangePWD" class="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">Modifier</a>
        </div>
      </li>
      <li class="flex items-center justify-between gap-x-6 py-5 bg-white px-4">
        <div class="min-w-0">
          <div class="flex items-start gap-x-3">
            <h3 class="text-base font-semibold leading-7 text-gray-900">Supprimer le compte</h3>
          </div>
          <div class="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
            <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Le compte ne peut plus être récupéré une fois supprimé.</p>
          </div>
        </div>
        <div class="flex flex-none items-center gap-x-4">
          <a class="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">Supprimer définitivement</a>
        </div>
      </li>
    </ul>


  </div>
</div>











