import { Component } from '@angular/core';
import {BffApiService} from "../../../shared/bff-api.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ToasterService} from "../../../shared/service/toaster.service";
import {retailer} from "../../../../../../fe-global-components/constants/retailer";
import {location} from "../../../../../../fe-global-components/constants/location";

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrl: './profil.component.scss'
})
export class ProfilComponent {
  cashProgrammForm: FormGroup;
  contactInfos: FormGroup;
  publicInfos: FormGroup;
  editCashInfos: boolean = false
  editPersonnalInfos: boolean = false
  cashProgram: any[] =  []
  user = retailer
  isChangePublicInfos: boolean= false


  constructor(private bff: BffApiService,
              private fb: FormBuilder,
              private toaster: ToasterService,) {
    this.cashProgrammForm  = this.fb.group({
      name: [null, Validators.required],
      url: [null, Validators.required],
      secret: [null, Validators.required],
    })
    this.contactInfos  = this.fb.group({
      firstname: [null, Validators.required],
      lastname: [null, Validators.required],
      phone: [null, Validators.required],
    })
    this.publicInfos  = this.fb.group({
      name: [null, Validators.required],
      street: [null, Validators.required],
      streetnumber: [null, Validators.required],
      zip: [null, Validators.required],
      city: [null, Validators.required],
      state: [null, Validators.required],
      description: [null, Validators.required],
      logo: [null, Validators.required],
    })
    this.bff.getSecret().subscribe(data => {
      if (data.length == 0) {
        return
      }
      this.cashProgram = data
    })
    this.bff.getProfile().subscribe(data => {
      this.user = data
    })
  }

  toggleEditCashInfos() {
    this.editCashInfos  = !this.editCashInfos
  }
  toggleEditPersonalInfos() {
    this.editPersonnalInfos = !this.editPersonnalInfos
  }

  sendCashProgramChanges(form: FormGroup) {
    if (form.invalid) {
      this.toaster.showInfo()
      return;
    }
    this.bff.postSecret(form.value).subscribe(data => {
      this.toaster.showSuccess()
      this.toggleEditCashInfos()
    })
  }
  sendProfileChanges(form: FormGroup) {
    console.log(form.value)
    if (form.invalid) {
      this.toaster.showInfo()
      return;
    }
    this.bff.putProfileInfo(form.value).subscribe(data => {
      this.toaster.showSuccess()
    })
  }




  changePassword: FormGroup = this.fb.group({
    now_password: [null, Validators.required],
    password: [null, Validators.required],
    repeat_password: [null, Validators.required],
  })

  isChangePWD: boolean = false;


  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.changePassword = this.fb.group({
      email: [null, Validators.required],
      password: [null, Validators.required],
      password_confirmation: [null, Validators.required],
    })
  }
  submit() {
    // if (this.changePassword.invalid) {
    //   this.snack.open('Le formulaire est faux veuillez verifier les informations entrées')
    //   return
    // }
    // this.userService.changePWD(this.changePassword.value)
  }

  togglePWD() {
    this.isChangePWD = !this.isChangePWD
  }

  protected readonly location = location;

  updPublicInfos() {
    //TODO
  }

  togglePublicInfos() {
    this.isChangePublicInfos  = !this.isChangePublicInfos
  }
}
