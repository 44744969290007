import { Component } from '@angular/core';
import {products} from "../../../../../../fe-global-components/mockup_data/mockup_data";
import {BffApiService} from "../../../shared/bff-api.service";
import {Product} from "../../../../../../fe-global-components/interfaces/interfaces";
import {NavigationService} from "../../../shared/service/navigation.service";
import {MatTableDataSource} from "@angular/material/table";
import {ProductListService} from "../../../shared/objects/product-list.service";

@Component({
  selector: 'app-stocks',
  templateUrl: './stocks.component.html',
  styleUrls: ['./stocks.component.scss']
})
export class StocksComponent {
  isTableExpanded = false;
  productList = products

  dataProductsList = new MatTableDataSource();
  displayedColumns: string[] = [
    //'checkbox',
    'name', 'categories', 'brand', 'actions'];
  isAddVariant: boolean = false;
  //////////////////////////////////////////////



  protected products = products;

  constructor(private bff: BffApiService,
              private pService: ProductListService,
              private nav: NavigationService) {
    this.bff.getStock().subscribe(
      data => {
        this.products  = data
        this.dataProductsList.data = data
      }
    )
    this.dataProductsList.data = products
  }

  addProductToVestra(stockProd: Product) {
      this.pService.saveProduct(stockProd)
      this.nav.goToEditArticle()
  }

  refreshStock() {

    this.bff.getStock().subscribe(data  => console.log(data))
  }
}
