import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageLoginComponent } from './page/connexion/page-login/page-login.component';
import { Page404Component } from './page/page404/page404.component';
import { DashboardComponent } from './page/dashboard/dashboard.component';
import { StatsComponent } from './page/dashboard-children/stats/stats.component';
import { SalesComponent } from './page/dashboard-children/sales/sales.component';
import { StoreComponent } from './page/dashboard-children/store/store.component';
import { StocksComponent } from './page/dashboard-children/stocks/stocks.component';
import {CommonModule, NgForOf, NgIf} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MatTableModule} from "@angular/material/table";
import { EditArticleComponent } from './page/edit-article/edit-article.component';
import { OrderLineComponent } from './micro-component/order-line/order-line.component';
import { PageRegisterComponent } from './page/connexion/page-register/page-register.component';
import { PageResetPaswordComponent } from './page/connexion/page-reset-pasword/page-reset-pasword.component';
import {BffApiService} from "./shared/bff-api.service";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import { HomeComponent } from './page/dashboard-children/home/home.component';
import {MatListModule} from "@angular/material/list";
import {MatButtonModule} from "@angular/material/button";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {ToastrModule} from "ngx-toastr";
import {ProductVariantFormComponent} from "./micro-component/product-variant-form/product-variant-form.component";
import {RouterOutlet} from "@angular/router";
import {MatDialogModule} from "@angular/material/dialog";
import {ProfilComponent} from "./page/dashboard-children/profil/profil.component";
import {ErrorInterceptor} from "./shared/service/error.interceptor"
import {MatSnackBarModule} from "@angular/material/snack-bar";

@NgModule({
  declarations: [
    AppComponent,
    PageLoginComponent,
    Page404Component,
    DashboardComponent,
    StatsComponent,
    SalesComponent,
    StoreComponent,
    StocksComponent,
    EditArticleComponent,
    OrderLineComponent,
    PageRegisterComponent,
    PageResetPaswordComponent,
    HomeComponent,
    ProfilComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgForOf,
        NgIf,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatTableModule,
        HttpClientModule,
        MatListModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDialogModule,
        FormsModule,
        MatSnackBarModule,
        ToastrModule.forRoot(),
        ProductVariantFormComponent,
        RouterOutlet,
        NgIf,
        ReactiveFormsModule,
    ],
  providers: [
    BffApiService,
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
