<section class="bg-stone-100 py-52">
  <div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-sm">
      <img class="mx-auto h-10 w-auto" src="assets/svg/logo.svg" alt="Vestra">
      <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Reset password</h2>
    </div>

    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      <form class="space-y-6" [formGroup]="reset_pwd_form">
        <div>
          <label for="email" class="label">Adresse email</label>
          <div class="mt-2">
            <input id="email" name="email" type="email"
                   formControlName="email"
                   autocomplete="email" required class="input">
          </div>
        </div>

        <div>
          <button type="submit" (click)="submit()" class="btn">Reset</button>
        </div>
      </form>

      <p class="mt-10 text-center text-sm text-gray-500">
        <a href="/login" class="link">Se connecter</a> | <a href="/register" class="link">Créer un compte</a>
      </p>
    </div>
  </div>

</section>
