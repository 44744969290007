<!--
  This example requires updating your template:


  <html class="h-full bg-white">
  <body class="h-full">

-->
<div class="flex min-h-full">
  <div class="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
    <div class="mx-auto w-full max-w-sm lg:w-96">
      <div>
        <img class="h-10 w-auto" src="../../../../assets/svg/logo.svg" alt="Vestra">
        <h2 class="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">Se connecter</h2>
        <p class="mt-2 text-sm leading-6 text-gray-500">
          Pas encore partenaire ?
          <a (click)="nav.goToRegister()" class="font-semibold text-stone-600 hover:text-stone-500">S'inscrire</a>
        </p>
      </div>

      <div class="mt-10">
        <div>
          <form [formGroup]="loginForm" class="space-y-6">
            <div>
              <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Adresse email</label>
              <div class="mt-2">
                <input id="email" name="email"
                       type="email" autocomplete="email"
                       required
                       formControlName="email"
                       class="input">
              </div>
            </div>

            <div>
              <label for="password" class="block text-sm font-medium leading-6 text-gray-900">Mot de passe</label>
              <div class="mt-2">
                <input id="password" name="password" type="password"
                       autocomplete="current-password" required
                       formControlName="password"
                       class="input">
              </div>
            </div>

            <div class="flex items-center justify-between">
<!--              <div class="flex items-center">-->
<!--                <input id="remember-me" name="remember-me" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-stone-600 focus:ring-stone-600">-->
<!--                <label for="remember-me" class="ml-3 block text-sm leading-6 text-gray-700">Se souvenir de moi</label>-->
<!--              </div>-->

              <div class="text-sm leading-6">
                <a (click)="nav.goToResetPassword()" class="font-semibold text-stone-600 hover:text-stone-500">Mot de passe oublié ?</a>
              </div>
            </div>

            <div>
              <button
                type="submit"
                (click)="submit()"
                class="flex w-full justify-center rounded-md bg-stone-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-stone-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-600">
                Connexion</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="relative hidden w-0 flex-1 lg:block">
    <img class="absolute inset-0 h-full w-full object-cover" src="https://images.unsplash.com/photo-1496917756835-20cb06e75b4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80" alt="">
  </div>
</div>
