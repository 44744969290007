import { Injectable } from '@angular/core';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  constructor(private router: Router) { }
  goToLink(string: string) {
    this.router.navigate([string]);
  }
  goToRegister() {
    this.router.navigate(['/register/']);
  }
  goToLogin() {
    this.router.navigate(['/login/']);
  }
  goToResetPassword() {
    this.router.navigate(['/reset-password/']);
  }
  goToStocks(param?: any[]) {
    this.router.navigate(['/dashboard/stocks/']);
  }
  goToStore() {
    this.router.navigate(['/dashboard/store/']);
  }
  goToSales() {
    this.router.navigate(['/dashboard/sales/']);
  }
  goToStats() {
    this.router.navigate(['/dashboard/stats/']);
  }
  goToEditArticle() {
    this.router.navigate(['/dashboard/edit-product/']);
  }
  goToDashboard() {
    this.router.navigate(['/dashboard/']);
  }
  goToParameter() {
    this.router.navigate(['/dashboard/parameter/']);
  }



}
