<div class="flex items-center justify-between gap-x-6 py-3.5">
  <div class="min-w-0">
    <div class="flex items-start gap-x-3">
      <p class="text-sm font-semibold leading-6 text-gray-900">#{{order.id}} {{order.user.first_name}} {{order.user.last_name}}</p>
      <p [ngClass]="getClass(order.status)" *ngIf="!editStatus"
        class="rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset">{{order.status}}</p>
      <div *ngIf="editStatus">
        <form [formGroup]="statusForm">
          <label for="status" class="block text-sm font-medium leading-6 text-gray-900">Statut</label>
          <select id="status" name="status"
                  formControlName="status"
                  class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-stone-600 sm:text-sm sm:leading-6">
            <option *ngFor="let status of sale_status | keyvalue"
                    ng-model="status"
                    value="{{status.key}}">{{status.value}}</option>
          </select>
        </form>
      </div>
    </div>
    <div class="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
      <p class="whitespace-nowrap">Commande passée le <time datetime="2023-03-17T00:00Z">March 17, 2023</time></p>
    </div>
  </div>
  <div class="flex flex-none items-center gap-x-4">
    <a *ngIf="!editStatus" class="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">Confirmation de commande</a>
    <a *ngIf="!editStatus" class="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">Ticket de caisse</a>
    <a *ngIf="!editStatus" (click)="toggleStatus()" class="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">Modifier le status</a>
    <a *ngIf="editStatus" (click)="toggleStatus()" class="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">Annuler</a>
    <a *ngIf="editStatus" (click)="saveStatus(order.id)" class="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">Sauvegarder</a>
    <a *ngIf="!editStatus" (click)="toggleDetails()" class="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">Détails</a>

    <div class="relative flex-none">
<!--      <button (click)="toggleMenu()" type="button" class="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900" id="options-menu-0-button" aria-expanded="false" aria-haspopup="true">-->
<!--        <span class="sr-only">Open options</span>-->
<!--        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">-->
<!--          <path d="M10 3a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM10 8.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM11.5 15.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z" />-->
<!--        </svg>-->
<!--      </button>-->

      <!--
        Dropdown menu, show/hide based on menu state.

        Entering: "transition ease-out duration-100"
          From: "transform opacity-0 scale-95"
          To: "transform opacity-100 scale-100"
        Leaving: "transition ease-in duration-75"
          From: "transform opacity-100 scale-100"
          To: "transform opacity-0 scale-95"
      -->
      <div @AnimationTrigger0 *ngIf='isMenu' class="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="options-menu-0-button" tabindex="-1">
        <!-- Active: "bg-gray-50", Not Active: "" -->
        <a href="#" class="block px-3 py-1 text-sm leading-6 text-gray-900" role="menuitem" tabindex="-1" id="options-menu-0-item-0">
          Voir<span class="sr-only">, commande {{order.id}}</span></a>
        <a href="#" class="block px-3 py-1 text-sm leading-6 text-gray-900" role="menuitem" tabindex="-1" id="options-menu-0-item-1">
          <span class="sr-only">, commande {{order.id}}</span></a>
<!--        <a href="#" class="block px-3 py-1 text-sm leading-6 text-gray-900" role="menuitem" tabindex="-1" id="options-menu-0-item-2">-->
<!--          Supprimer<span class="sr-only">, commande {{order.id}}</span></a>-->
      </div>
    </div>
  </div>
</div>
<div @AnimationTrigger0 *ngIf='isDetail' class="mb-8">
  <div class="-mx-4 mt-2 sm:-mx-0">
    <table class="min-w-full divide-y">
      <thead>
      <tr>
        <th scope="col" class="py-3.5 text-left text-xs font-semibold text-gray-900">Informations générales</th>
      </tr>
      </thead>
      <tbody class="divide-y divide-gray-200 bg-white">
      <tr>
        <td class="whitespace-nowrap py-4 text-sm text-gray-500">
          {{order.user.phone}}<br>
          {{order.user.email}}
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="-mx-4 mt-2 sm:-mx-0">
    <table class="min-w-full divide-y">
      <thead>
      <tr>
        <th scope="col" class="py-3.5 text-left text-xs font-semibold text-gray-900">Adresse de Facturation</th>
        <th scope="col" class="py-3.5 text-left text-xs font-semibold text-gray-900">Adresse de Livraison</th>
      </tr>
      </thead>
      <tbody class="divide-y divide-gray-200 bg-white">
      <tr>
        <td class="whitespace-nowrap py-4 text-sm text-gray-500">
          {{order.user.invoice_address}}</td>
        <td class="whitespace-nowrap py-4 text-sm text-gray-500">
          {{order.user.delivery_address}}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="-mx-4 mt-2 sm:-mx-0">
    <p class="text-xs font-semibold text-gray-900">Articles</p>
    <table class="min-w-full divide-y divide-gray-300">
      <tbody class="divide-y divide-gray-200 bg-white">
      <tr *ngFor="let article of order.articles">
        <td class="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
          <img class="inline-block h-8 w-8 rounded-md" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
          {{article.quantity}}x {{article.name}}, {{article.brand}}
          <dl class="font-normal lg:hidden">
            <dd class="mt-1 truncate text-gray-700">{{article.color}}</dd>
            <dd class="mt-1 truncate text-gray-500 sm:hidden">{{article.size}}</dd>
          </dl>
        </td>
        <td class="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">{{article.color}}</td>
        <td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{{article.size}}</td>
        <td class="px-3 py-4 text-sm text-gray-500">{{article.new_price}} CHF</td>
        <td class="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
<!--          <a href="#" class="text-stone-600 hover:text-stone-900">Edit<span class="sr-only">, {{article.name}}, {{article.brand}}</span></a>-->
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

