import { Component } from '@angular/core';
import {NavigationService} from "../../../shared/service/navigation.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BffApiService} from "../../../shared/bff-api.service";
import {MatSnackBar} from "@angular/material/snack-bar";
@Component({
  selector: 'app-page-login',
  templateUrl: './page-login.component.html',
  styleUrls: ['./page-login.component.scss']
})
export class PageLoginComponent {
  loginForm: FormGroup;
  constructor(private fb: FormBuilder,
              private bff: BffApiService,
              private _snackBar: MatSnackBar,
              public nav: NavigationService) {
    this.loginForm  = this.fb.group({
      email: [null, Validators.required],
      password: [null, Validators.required],
    })
  } // constructor

  submit() {
    if (this.loginForm.invalid) {
      this._snackBar.open("Le formulaire est mal rempli", 'OK',{ duration: 3 * 1000 });
      return
    }
    this.bff.getCookie().subscribe(data => {
      this.bff.login(this.loginForm.value).subscribe(data => {
        this.nav.goToDashboard()
      })
    })
  }


}
