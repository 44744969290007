import {Component} from '@angular/core';
import {orders, order_status} from "../../../../../../fe-global-components/mockup_data/mockup_data";

import {Order, OrderStatus} from "../../../../../../fe-global-components/interfaces/interfaces";

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss'],
})
export class SalesComponent {
  protected readonly order_status: OrderStatus[] = order_status;
  protected readonly orders: Order[] = orders;
  protected filteredOrder: Order[] = this.orders

  constructor() { }


  onChange($event: any) {
    const filter = $event.target.value
    if (!filter || filter == '-1') {
      this.filteredOrder = this.orders;
      return;
    }
    this.filteredOrder = this.orders.filter(
      order => order?.status.toLowerCase().includes(filter.toLowerCase())
    );
  }
}

