import {Component, OnInit} from '@angular/core';
import {animate, style, transition, trigger} from "@angular/animations";
import {ActivatedRoute} from "@angular/router";
import {BffApiService} from "../../shared/bff-api.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NavigationService} from "../../shared/service/navigation.service";
import {Product} from "../../../../../fe-global-components/interfaces/interfaces";
import {ProductListService} from "../../shared/objects/product-list.service";
import {product} from "../../../../../fe-global-components/mockup_data/mockup_data";
import {ToasterService} from "../../shared/service/toaster.service";
import {DeleteItemComponent} from "../../shared/delete-item/delete-item.component";
import {MatDialog} from "@angular/material/dialog";

interface ListItem {
  id: string;
  name: string;
  description: string;
}

@Component({
  selector: 'app-edit-article',
  templateUrl: './edit-article.component.html',
  styleUrls: ['./edit-article.component.scss'],
  animations: [
    trigger('opacityLeave', [
      transition(':leave', [
        style({ opacity: 1 }),
        animate('100ms ease-in', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class EditArticleComponent implements OnInit {
  productVarianForm: FormGroup;
  salesValues = [
    {name: "20%", value: "20"},
    {name: "30%", value: "30"},
    {name: "40%", value: "40"},
    {name: "50%", value: "50"},
    {name: "60%", value: "60"},
    {name: "70%", value: "70"},
    {name: "80%", value: "80"},
  ]
  list: ListItem[] = [
    {
      id: "1",
      name: "Publié",
      description: "This job posting can be viewed by anyone who has the link."
    },
    {
      id: "2",
      name: "Brouillon",
      description: "This job posting will no longer be publicly accessible."
    }
  ]

  isSelect = false;
  current: ListItem = this.list[0];
  highlight: ListItem | undefined;

  product: Product = product;
  constructor(private route: ActivatedRoute,
              private dialog: MatDialog,
              private toaster: ToasterService,
              private fb: FormBuilder,
              private bff: BffApiService,
              public nav: NavigationService,
              private prodService: ProductListService) {
    // let productID = this.route.snapshot.paramMap.get('id')
    // if (productID) {
    //   this.bff.getProducts(productID).subscribe((res: API) => {
    //     //this.product = <Product>res.data
    //   })
    // }
    // else {
    //   //this.initForm()
    // }
    this.productVarianForm = this.fb.group({
      name: [null, Validators.required],
      brand: [null, Validators.required],
      categories: [null, Validators.required],
      description: [null, Validators.required],
      old_price: [null, Validators.required],
      sale: [null, Validators.required],
    })
    console.log('dejiewoew')
  }// constructor

  ngOnInit() {
    this.prodService.currentNewProduct.subscribe(product => {
      this.product = product
      this.productVarianForm = this.fb.group({
        name: [this.product.name, Validators.required],
        brand: [this.product.brand, Validators.required],
        categories: [this.product.categories, Validators.required],
        description: [this.product.description, Validators.required],
        old_price: [this.product.old_price, Validators.required],
        sale: [null, Validators.required],
      })
    })
  }
  toggleSelect() {
    this.isSelect = !this.isSelect;
  }
  select(item: ListItem) {
    if(item.name == 'Brouillon') {
      this.throwAlerte(item)
      return
    }
    this.current = item;
    this.toggleSelect();
  }
  setHighlight(item: ListItem) {
    this.highlight = item;
  }

  throwAlerte(item : any): void {
    let result: false;
    this.dialog.open(DeleteItemComponent, {
      data: item,
    }).afterClosed().subscribe(res => {
      if (res == true) {
        this.current = this.list[1];
        this.toggleSelect();
      } else if ( res == false) {
       //  this.current = this.list[];
      }
    });
  }

  saveChanges() {
    if(this.productVarianForm.invalid){
      this.toaster.showInfo()
      //return
    }
    this.bff.editProductVariant(this.product.id, this.productVarianForm.value).subscribe(data => {
        this.toaster.showInfo()
        //TODO goback to product list
      this.nav.goToStocks()
      })
    this.nav.goToStore()

  }
}
