import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {Product, Secret} from "../../../../fe-global-components/interfaces/interfaces";
import axios from "axios";

@Injectable({
  providedIn: 'root'
})
export class BffApiService {
  apiUrl: string =  environment.vestraApiUrl  +  'api/v1/company';
  headers = {'Accept': 'application/json'};
  options = { headers: this.headers, withCredentials: true }


  constructor(private http: HttpClient) {
  }

  // SECRET RELATED
  // postSecret(): Observable<any> {
  //   const url: string = environment.vestraApiUrl + 'sanctum/csrf-cookie';
  //   return axios.post(url, '',this.options);
  // }

  getCookie(): Observable<any> {
    const url: string = environment.vestraApiUrl + 'sanctum/csrf-cookie';
    return this.http.get(url, this.options);
  }

  // SESSION RELATED
  login(form: any): Observable<any> {
    const url: string = `${this.apiUrl}/login?email=${form.email}&password=${form.password}`
    return this.http.post(url, '', this.options);
  }

  logout(): Observable<any> {
    const url: string = `${this.apiUrl}/logout`
    return this.http.post(url, '',this.options);
  }

  forgotPassword(form: any): Observable<any> {
    const url: string = `${this.apiUrl}/login?email=${form.email}`
    return this.http.post(url, '',this.options);
  }

  /**
    * @deprecated TODO what is this endpoint for ?
   * * */
  updatePassword(): Observable<any> {
    const url: string = `${this.apiUrl}/update-password`
    return this.http.post(url, '',this.options);
  }

  resetPassword(): Observable<any> {
    const url: string = `${this.apiUrl}/reset-password`
    return this.http.get(url,this.options);
  }

  // REGISTRATION RELATED
  register(form: any): Observable<any> {
    const url: string = `${this.apiUrl}/register`
    return this.http.post(url, form,this.options);
  }

  getVerify(id: string|number, hash: string|number): Observable<any> {
    const url: string = `${this.apiUrl}/email/verify/${id}/${hash}`
    return this.http.get(url,this.options);
  }

  postVerify(): Observable<any> {
    const url: string = `${this.apiUrl}/email/verification-notification`
    return this.http.post(url, '',this.options);
  }



  getConfirmedPasswordStatus(): Observable<any> {
    const url: string = `${this.apiUrl}/confirmed-password-status`
    return this.http.get(url,this.options);
  }

  confirmPassword(form: any): Observable<any> {
    const url: string = `${this.apiUrl}/confirm-password?password=${form.password}`
    return this.http.post(url, '',this.options);
  }

  getStock(): Observable<any> {
    const url: string = `${this.apiUrl}/stock`
    return this.http.get(url, this.options);
  }

  getSecret(): Observable<any> {
    const url: string = `${this.apiUrl}/settings/secrets`
    return this.http.get(url,this.options);
  }
  postSecret(secret: Secret): Observable<any> {
    const url: string = `${this.apiUrl}/settings/secrets?name=bcprisme&url=https://vpn.bestinfo.ch:54789&secret=9jIGiuZer87UBYw1ey4ui5AgGd6E7Ss`
    return this.http.post(url, secret, this.options);
  }

  getProducts(id: string): Observable<any> {
    const url: string = `${this.apiUrl}/product/id=` + id
    return this.http.get(url,this.options);
  }

  getProfile(): Observable<any> {
    const url: string = `${this.apiUrl}/profile-information`
    return this.http.get(url,this.options);
  }

  putProfileInfo(form: any): Observable<any> {
    const url: string = `${this.apiUrl}/profile-information`
    return this.http.put(url, form, this.options);
  }

  /**
   * TODO NICOLAS
   */
  addProduct(stockProd: Product) {
    const url: string = `${this.apiUrl}/product/`
    return this.http.post(url, stockProd,this.options);
  }
  addProductVariant(product_id: string|number, form: any) {
    const url: string = `${this.apiUrl}/product/id=` + product_id
    return this.http.post(url, form,this.options);
  }
  editProductVariant(product_id: any, form: any): Observable<any> {
    const url: string = `${this.apiUrl}/product/id=` + product_id
    return this.http.put(url, form,this.options);
  }
  editSaleStatus(order_id: any, newStatus: any) {
    const url: string = `${this.apiUrl}/product/id=` + order_id
    return this.http.put(url, newStatus,this.options);
  }


  getUserRole() {
    const url: string = `${this.apiUrl}/user/role`
    return this.http.put(url,this.options);
  }

  sendVerificationEmail() {
    const url = `${this.apiUrl}/email/verification-notification`
    return this.http.post(url,'' ,this.options)
  }
}
