import { Injectable } from '@angular/core';
import {BffApiService} from "../bff-api.service";
import {Role} from "../../../../../fe-global-components/interfaces/enums";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private bff: BffApiService) { }

  getUserRole(): Role { // TODO
    this.bff.getUserRole().subscribe(data => {
      return data
    })
    return Role.RETAILER;
  }
}
