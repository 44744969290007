import { Component } from '@angular/core';
import {location} from "../../../../../../fe-global-components/constants/location";
import {NavigationService} from "../../../shared/service/navigation.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BffApiService} from "../../../shared/bff-api.service";
import {ToasterService} from "../../../shared/service/toaster.service";

@Component({
  selector: 'app-page-register',
  templateUrl: './page-register.component.html',
  styleUrls: ['./page-register.component.scss']
})
export class PageRegisterComponent {
  protected readonly states = location;
  registerForm: FormGroup;

  constructor(private fb: FormBuilder,
              private bff: BffApiService,
              private toaster: ToasterService,
              public nav: NavigationService) {
    this.registerForm = this.fb.group({
      name: [null, Validators.required],
      street: [null, Validators.required],
      street_number: [null, Validators.required],
      zip: [null, Validators.required],
      city: [null, Validators.required],
      state: [null, Validators.required],
      description: [null, Validators.required],
      logo: [null],
      firstname: [null, Validators.required],
      lastname: [null, Validators.required],
      email: [null, Validators.required],
      phone: [null, Validators.required],
      password: [null, Validators.required],
      password_confirmation: [null, Validators.required],
    })
  }

  sendRequest() {
    console.log(this.registerForm.value)
    if(this.registerForm.invalid) {
      this.toaster.showInfo()
      return
    }
    this.bff.register(this.registerForm.value).subscribe(data => {
      console.log(data)
      // todo: go to resume the request and say thank you !
    })
  }


}
