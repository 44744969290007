import { Injectable } from '@angular/core';
import {ToastrService} from "ngx-toastr";

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor(private toastr: ToastrService) {
    // ToastrService.success/error/warning/info/show()
  }

  showSuccess() {
    this.toastr.success('Success!', 'Success');
  }

  showError() {
    this.toastr.error('New ' + 'xxx' + ' has been created', 'Error');
  }

  showWarning() {
    this.toastr.warning('New ' + 'xxx' + ' has been created', 'Warning');
  }

  showInfo() {
      this.toastr.info('Some info, mybe form file went wrong, maybe other.. ?', 'Infos');
  }

  showShow() {
    this.toastr.show('New ' + 'xxx' + ' has been created', 'Show time!');
  }


}
