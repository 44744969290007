import {Component, OnInit} from '@angular/core';
import {products, colors, sizes} from "../../../../../../fe-global-components/mockup_data/mockup_data";
import {MatTableDataSource} from "@angular/material/table";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {Router} from "@angular/router";
import {Color, Product, ProductVariant, Size} from "../../../../../../fe-global-components/interfaces/interfaces";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BffApiService} from "../../../shared/bff-api.service";
import {ToasterService} from "../../../shared/service/toaster.service";
import {ProductListService} from "../../../shared/objects/product-list.service";
import {MatDialog} from "@angular/material/dialog";
import {DeleteItemComponent} from "../../../shared/delete-item/delete-item.component";


@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class StoreComponent implements OnInit {
  colors: Color[] = colors;
  sizes: Size[] = sizes;
  isTableExpanded = false;
  productList = products

  dataProductsList = new MatTableDataSource();
  displayedColumns: string[] = [
    //'checkbox',
    'name', 'categories', 'brand', 'actions'];
  isAddVariant: boolean = false;
  productVariantForm: FormGroup;

  constructor(private router: Router,
              public dialog: MatDialog,
              private fb: FormBuilder,
              private toast: ToasterService,
              private bff: BffApiService,
              private prodService: ProductListService) {
    this.productVariantForm = this.fb.group({
      color: [null, Validators.required],
      size: [null, Validators.required],
      quantity: [null, Validators.required],
    })
  }
  ngOnInit() {
    this.bff.getStock().subscribe(data => console.log(data))
    this.productList.forEach(p => {
      p.products.forEach(pv => pv.isEdit = false)
    })
    this.dataProductsList.data = products;
  }

  // Toggle Rows
  toggleTableRows() {
    this.isTableExpanded = !this.isTableExpanded;

    this.dataProductsList.data.forEach((row: any) => {
      row.isExpanded = this.isTableExpanded;
    })
  }

  editVariant(article: Product) {
    this.prodService.changeProduct(article)
    this.router.navigate(['/dashboard/edit-product/' + article.id]);
  }

  toggleVariante() {
    this.isAddVariant = !this.isAddVariant
  }

  toggleEdit(article: ProductVariant) {
    article.isEdit = !article.isEdit
  }

  filterProduct() {
    // TODO filter products
  }

  addItemInList(product_id: string|number , values: any, id: any) {
    this.productList.find(product => product.id === product_id)?.products.push(
      {
        id: 3243432,
        color: this.colors.find(c => c.name === values.color)!,
        size: this.sizes.find(c => c.name === values.size)!,
        quantity: values.quantity,
      })
  }

  addVariant(product_id: string|number) {
    if (this.productVariantForm.invalid) {
      this.toast.showInfo()
      return
    }
    console.log('form valid')
    this.bff.addProductVariant(product_id, this.productVariantForm.value).subscribe(data => {
      console.log(data)
      this.addItemInList(product_id, this.productVariantForm.value, data)
      this.toast.showSuccess()
      this.productVariantForm.reset();
    })
    //todo remove after linking to API
    this.addItemInList(product_id, this.productVariantForm.value, 23)
    this.toast.showSuccess()
    this.productVariantForm.reset();
  }

  deleteItem(item: any) {
    this.dialog.open(DeleteItemComponent, {
      data: item,
    }).afterClosed().subscribe(result => {
      if (result == false) {

      } else if (result == true) {

      }
      console.log(`${result}`);
    });
  }

}
