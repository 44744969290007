import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PageLoginComponent} from "./page/connexion/page-login/page-login.component";
import {Page404Component} from "./page/page404/page404.component";
import {DashboardComponent} from "./page/dashboard/dashboard.component";
import {StocksComponent} from "./page/dashboard-children/stocks/stocks.component";
import {StoreComponent} from "./page/dashboard-children/store/store.component";
import {SalesComponent} from "./page/dashboard-children/sales/sales.component";
import {StatsComponent} from "./page/dashboard-children/stats/stats.component";
import {PageRegisterComponent} from "./page/connexion/page-register/page-register.component";
import {PageResetPaswordComponent} from "./page/connexion/page-reset-pasword/page-reset-pasword.component";
import {EditArticleComponent} from "./page/edit-article/edit-article.component";
import {ProfilComponent} from "./page/dashboard-children/profil/profil.component";
import {routeGuard} from "./shared/service/route.guard";
import {Role} from "../../../fe-global-components/interfaces/enums";

const routes: Routes = [
  {
    path: '',
    component: PageLoginComponent,
  },
  {
    path: 'login',
    component: PageLoginComponent,
  },
  {
    path: 'register',
    component: PageRegisterComponent,
  },
  {
    path: 'reset-password',
    component: PageResetPaswordComponent,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [routeGuard],
    data: {
      roles: [ Role.RETAILER ]
    },
    children: [
      {
        path: '',
        //component: HomeComponent,
        redirectTo: 'store',
        pathMatch: 'full',
      },
      {
        path: 'stocks',
        component: StocksComponent,
        canActivate: [routeGuard],
        data: {
          roles: [ Role.RETAILER ]
        },
      },
      {
        path: 'store',
        component: StoreComponent,
        canActivate: [routeGuard],
        data: {
          roles: [ Role.RETAILER ]
        },
      },
      {
        path: 'sales',
        component: SalesComponent,
        canActivate: [routeGuard],
        data: {
          roles: [ Role.RETAILER ]
        },
      },
      {
        path: 'stats',
        component: StatsComponent,
        canActivate: [routeGuard],
        data: {
          roles: [ Role.RETAILER ]
        },
      },
      {
        path: 'parameter',
        component: ProfilComponent,
        canActivate: [routeGuard],
        data: {
          roles: [ Role.RETAILER ]
        },
      },
      {
        path: 'edit-product/:id',
        component: EditArticleComponent,
        canActivate: [routeGuard],
        data: {
          roles: [ Role.RETAILER ]
        },
      },
      {
        path: 'edit-product',
        component: EditArticleComponent,
        canActivate: [routeGuard],
        data: {
          roles: [ Role.RETAILER ]
        },
      },
    ]
  },
  {
    path: '404',
    component: Page404Component,
  },
  {
    path: '**', redirectTo: '404'
  }

];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
